@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-Thin.woff2') format('woff2'),
        url('src/inter/Inter-Thin.woff') format('woff'),
        url('src/inter/Inter-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-ExtraLight.woff2') format('woff2'),
        url('src/inter/Inter-ExtraLight.woff') format('woff'),
        url('src/inter/Inter-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-Light.woff2') format('woff2'),
        url('src/inter/Inter-Light.woff') format('woff'),
        url('src/inter/Inter-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-Regular.woff2') format('woff2'),
        url('src/inter/Inter-Regular.woff') format('woff'),
        url('src/inter/Inter-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-Medium.woff2') format('woff2'),
        url('src/inter/Inter-Medium.woff') format('woff'),
        url('src/inter/Inter-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-SemiBold.woff2') format('woff2'),
        url('src/inter/Inter-SemiBold.woff') format('woff'),
        url('src/inter/Inter-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}
@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-Bold.woff2') format('woff2'),
        url('src/inter/Inter-Bold.woff') format('woff'),
        url('src/inter/Inter-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-ExtraBold.woff2') format('woff2'),
        url('src/inter/Inter-ExtraBold.woff') format('woff'),
        url('src/inter/Inter-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-Black.woff2') format('woff2'),
        url('src/inter/Inter-Black.woff') format('woff'),
        url('src/inter/Inter-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}


@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-ThinItalic.woff2') format('woff2'),
        url('src/inter/Inter-ThinItalic.woff') format('woff'),
        url('src/inter/Inter-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-ExtraLightItalic.woff2') format('woff2'),
        url('src/inter/Inter-ExtraLightItalic.woff') format('woff'),
        url('src/inter/Inter-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-LightItalic.woff2') format('woff2'),
        url('src/inter/Inter-LightItalic.woff') format('woff'),
        url('src/inter/Inter-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-Italic.woff2') format('woff2'),
        url('src/inter/Inter-Italic.woff') format('woff'),
        url('src/inter/Inter-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-MediumItalic.woff2') format('woff2'),
        url('src/inter/Inter-MediumItalic.woff') format('woff'),
        url('src/inter/Inter-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-SemiBoldItalic.woff2') format('woff2'),
        url('src/inter/Inter-SemiBoldItalic.woff') format('woff'),
        url('src/inter/Inter-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-BoldItalic.woff2') format('woff2'),
        url('src/inter/Inter-BoldItalic.woff') format('woff'),
        url('src/inter/Inter-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-ExtraBoldItalic.woff2') format('woff2'),
        url('src/inter/Inter-ExtraBoldItalic.woff') format('woff'),
        url('src/inter/Inter-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Inter';
    src: url('src/inter/Inter-BlackItalic.woff2') format('woff2'),
        url('src/inter/Inter-BlackItalic.woff') format('woff'),
        url('src/inter/Inter-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}